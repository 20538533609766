import { flattenEach } from '@turf/meta';
import { featureCollection } from '@turf/helpers';

/**
 * Flattens any {@link GeoJSON} to a {@link FeatureCollection} inspired by [geojson-flatten](https://github.com/tmcw/geojson-flatten).
 *
 * @name flatten
 * @param {GeoJSON} geojson any valid GeoJSON Object
 * @returns {FeatureCollection<any>} all Multi-Geometries are flattened into single Features
 * @example
 * var multiGeometry = turf.multiPolygon([
 *   [[[102.0, 2.0], [103.0, 2.0], [103.0, 3.0], [102.0, 3.0], [102.0, 2.0]]],
 *   [[[100.0, 0.0], [101.0, 0.0], [101.0, 1.0], [100.0, 1.0], [100.0, 0.0]],
 *   [[100.2, 0.2], [100.8, 0.2], [100.8, 0.8], [100.2, 0.8], [100.2, 0.2]]]
 * ]);
 *
 * var flatten = turf.flatten(multiGeometry);
 *
 * //addToMap
 * var addToMap = [flatten]
 */
function flatten(geojson) {
  if (!geojson) throw new Error("geojson is required");

  var results = [];
  flattenEach(geojson, function (feature) {
    results.push(feature);
  });
  return featureCollection(results);
}

export default flatten;
