import i18n from "i18n-js";
/**
 * Translates text.
 *
 * @param key The i18n key.
 * @param options The i18n options.
 * @returns The translated text.
 *
 * @example
 * Translations:
 *
 * ```en.ts
 * {
 *  "hello": "Hello, {{name}}!"
 * }
 * ```
 *
 * Usage:
 * ```ts
 * import { translate } from "i18n-js"
 *
 * translate("common.ok", { name: "world" })
 * // => "Hello world!"
 * ```
 */
export function translate(key, options) {
    return i18n.t(key, options);
}
export function translateWithLocale(key, locale, options) {
    i18n.locale = locale;
    return i18n.t(key, options);
}
export function getCurrentLocale() {
    return i18n.currentLocale();
}
