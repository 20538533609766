import i18n from "i18n-js";
// if English isn't your default language, move Translations to the appropriate language file.
import en from "./en";
import fr from "./fr";
i18n.fallbacks = true;
/**
 * we need always include "*-US" for some valid language codes because when you change the system language,
 * the language code is the suffixed with "-US". i.e. if a device is set to English ("en"),
 * if you change to another language and then return to English language code is now "en-US".
 */
i18n.translations = { fr, en, "en-US": en };
export function setI18nLocale(locale) {
    i18n.locale = locale;
}
