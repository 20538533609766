/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Roboto_100Thin = require('./Roboto_100Thin.ttf');
export const Roboto_100Thin_Italic = require('./Roboto_100Thin_Italic.ttf');
export const Roboto_300Light = require('./Roboto_300Light.ttf');
export const Roboto_300Light_Italic = require('./Roboto_300Light_Italic.ttf');
export const Roboto_400Regular = require('./Roboto_400Regular.ttf');
export const Roboto_400Regular_Italic = require('./Roboto_400Regular_Italic.ttf');
export const Roboto_500Medium = require('./Roboto_500Medium.ttf');
export const Roboto_500Medium_Italic = require('./Roboto_500Medium_Italic.ttf');
export const Roboto_700Bold = require('./Roboto_700Bold.ttf');
export const Roboto_700Bold_Italic = require('./Roboto_700Bold_Italic.ttf');
export const Roboto_900Black = require('./Roboto_900Black.ttf');
export const Roboto_900Black_Italic = require('./Roboto_900Black_Italic.ttf');
